import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'
class formConfig {
  protected vm?: Vue
  protected formKey?: string

  get(vm: Vue, formKey: string) : EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      pageTitle: (formMeta: any, formData: AnyObject) => this.vm?.$helper.getSiteUserName(formData),
      metaAction: {
        resetPassword: {
          label: 'action.reset-password',
          color: 'orange',
          small: false,
          callback: () => {
            // @ts-ignore
            this.vm.$helper.account.verify(() => {
              // @ts-ignore
              this.vm.$router.push({
                name: 'reset-password',
                // @ts-ignore
                query: { path: this.vm.$route.fullPath }
              })
            })
          },
        },
      },
      blocks: [
        {
          title: 'form_block.base',
          data: {
            avatar: {
              label: 'data.avatar',
              type: 'photo',
              grid: {xl: 12, lg: 12, md: 12},
              photoType: 'site_admin',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return window.eagleLodash.isEqual(originalData, data) === false
              },
            },
            name: {
              required: true,
              label: 'site_admin.data.name',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            email: {
              required: true,
              email: true,
              label: 'site_admin.data.email',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            locked: {
              disabled: true,
              label: 'site_admin.data.locked',
              type: 'switch',
            },
            roles: {
              disabled: true,
              label: 'site_admin.data.roles',
              create: () => true,
              type: 'selection',
              multiple: true,
              options: (formInfo: FormInfoInterface) => {
                if(!formInfo.formMeta) return []
                if(!Array.isArray(formInfo.formMeta.roles)) return []
                return formInfo.formMeta.roles.map(role => ({
                  value: role,
                  text: role,
                }))
              },
            },
            groups: {
              label: 'module.site_admin_group',
              type: 'item-list',
              itemTitle: item => item.name,
              itemSubtitle: item => item.description,
              defaultAvatarIcon: 'fa fa-user-friends',
              grid: { xl: 12, lg: 12, md: 12 },
              disabled: true,
            },
          },
        },
        {
          title: 'module.site_admin_device',
          data: {
            device: {
              type: 'list-component',
              grid: { xl: 12, lg: 12, md: 12 },
              listComponent: () => import('./siteAdminProfile/siteAdminDeviceList.vue'),
            },
          },
        },
      ],
      dataAction: {
        delete: false,
      },
    }
  }
}

export default new formConfig()
